import { Component, OnInit, NgZone } from '@angular/core';
import { ApiService } from '../api.service';
import { Router } from "@angular/router";
import {ToastrService} from 'ngx-toastr';

@Component({
	selector: 'app-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
	user: any = { email: '', password: '' };
	year: number = new Date().getFullYear();
	ScreenHeight: number = window.innerHeight;
	loadershow: boolean = false;
	loginErrorshow: boolean = false;
	checkCapsLock: boolean = false;
	loginFormErr: string = "";
	data:any = null;
	step:number = 1;
	showQR = false;
	qrToShow:any;
	auth: any = { otp: null };
	qrStep = 1;
	userType:any = localStorage.getItem('userType');
	isPassword = true;
	
	constructor(private toastr: ToastrService,private apiService: ApiService,private router: Router,private zone: NgZone) {}
	
	ngOnInit(): void {
		if(localStorage.getItem('token') != null && localStorage.getItem('token') != undefined){
			if(this.userType=='company'){
				this.router.navigate(['/app/dashboard/mcmi4']);
			}else if(this.userType=='branch'){
				this.router.navigate(['/app/appointmentList/company']);
			}else if(this.userType=='wellness'){
				this.router.navigate(['/app/wellness']);
			}else if(this.userType=='vessel'){
				this.router.navigate(['/app/wellness']);
			} else {
				this.router.navigate(['/app/appointmentList']);
			}
		}
		if(localStorage.getItem('sessionexpire') == "true"){
			this.loginErrorshow = true;
			this.loginFormErr = "Your session has been expired.";
			setTimeout(() => {
				this.loginErrorshow = false;
				localStorage.removeItem('sessionexpire')
			}, 5000);
		}
		setTimeout(() => {
			this.ScreenHeight=(window.innerHeight);
		}, 0);
	}

	togglePassword(){
		this.isPassword = !this.isPassword;
		if(this.isPassword){
			$("#user_password").attr("type","password");
		}else{
			$("#user_password").attr("type","text");
		}
	}
	
	submitForm(user: any) {
		if(!user.email){
			this.toastr.warning("Please enter email-id", 'Error',{
				timeOut:3000,
				tapToDismiss: true,
				closeButton: true
			});
			return;
		}
		if(!user.password){
			this.toastr.warning("Please enter password", 'Error',{
				timeOut:3000,
				tapToDismiss: true,
				closeButton: true
			});
			return;
		}
		this.loadershow = true;
		this.apiService.loginAPI(user).subscribe((data:any) => {
			this.loadershow = false;
			this.data = data;
			/* this.data.enable2fa=true;this.data.first_login=false; */
			if(this.data.enable2fa && this.data.first_login){
				this.step = 2;
				this.showQR = true;
				this.generateQR();
			}else if(this.data.enable2fa && !this.data.first_login){
				this.step = 2;
				this.showQR = false;
				this.qrStep = 2;
			}else{
				this.proceedLogin();
			}
		},(error:any)=>{
			this.loadershow = false;
			this.loginErrorshow = true;
			this.loginFormErr = "Email-Id Or Password is incorrect.";
			setTimeout(() => {
				this.loginErrorshow = false;
			}, 5000);
		});
	}

	next(){
		this.qrStep = 2;
	}

	generateQR(){
		this.loadershow = true;
		let data:any = {
			Id:this.data.Id,
			userType:this.data.userType.toLowerCase(),
			user:this.data.user
		};
		this.apiService.generate_qr(data).subscribe((data:any) => {
			this.loadershow = false;
			this.createImageFromBlob(data);
		},(error:any)=>{
			this.loadershow = false;
			this.loginErrorshow = true;
			this.loginFormErr = "Something went wrong";
			setTimeout(() => {
				this.loginErrorshow = false;
			}, 5000);
		});
	}

	createImageFromBlob(image: Blob) {
		let reader = new FileReader();
		reader.addEventListener("load", () => {
			this.qrToShow = reader.result;
		}, false);
		if (image) {
			reader.readAsDataURL(image);
		}
	}

	verifyOTP(){
		if(!this.auth.otp){
			this.toastr.warning("Please enter OTP", 'Error',{
				timeOut:3000,
				tapToDismiss: true,
				closeButton: true
			});
			return;
		}
		this.loadershow = true;
		let data:any = {
			Id:this.data.Id,
			userType:this.data.userType.toLowerCase(),
			otp:this.auth.otp
		};
		this.apiService.verifyGoogleAuthOTP(data).subscribe((data:any) => {
			this.loadershow = false;
			this.proceedLogin();
		},(error:any)=>{
			this.loadershow = false;
			this.loginErrorshow = true;
			this.loginFormErr = "Invalid OTP";
			setTimeout(() => {
				this.loginErrorshow = false;
			}, 5000);
		});
	}

	proceedLogin(){
		localStorage.setItem('token', "Bearer "+this.data["key"]);
		localStorage.setItem('OwnerId', this.data["Id"]);
		localStorage.setItem("userEmail", this.data["email"]);
		localStorage.setItem("role", this.data["role"]);
		localStorage.setItem("userType", this.data["userType"].toLowerCase());
		localStorage.setItem("first_name", this.data["session"]["first_name"]);
		localStorage.setItem("last_name", this.data["session"]["last_name"]);
		if(this.data["userType"].toLowerCase()=='company'){
			this.router.navigate(['/app/dashboard/mcmi4']);
		}else if(this.data["userType"].toLowerCase()=='branch'){
			this.router.navigate(['/app/appointmentList/company']);
		}else if(this.data["userType"].toLowerCase()=='wellness'){
			this.router.navigate(['/app/wellness']);
		}else if(this.data["userType"].toLowerCase()=='vessel'){
			this.router.navigate(['/app/wellness']);
		} else {
			this.router.navigate(['/app/appointmentList']);
		}
	}
}
